<template>
  <UiBaseModal
    v-if="isMobile"
    v-model="popoverDrawerModel"
    :id="id"
    :trap-focus="false"
    :classes="classes?.drawer"
    @close="emit('close')"
  >
    <template #trigger="{ dir, ...triggerProps }">
      <slot v-bind="triggerProps" name="trigger" />
    </template>

    <div class="flex flex-col">
      <slot v-if="$slots.drawer" v-bind="{ close }" name="drawer" />
      <slot v-else v-bind="{ close }" />
    </div>
  </UiBaseModal>

  <UiPopover
    v-else
    v-model:model-value="popoverDrawerModel"
    @close="emit('close')"
    :classes="{
      body: cn('p-s shadow-[0_0_12px_2px_rgba(234,235,235,0.8)]', classes?.popover?.body),
    }"
  >
    <template #trigger>
      <slot name="trigger" />
    </template>

    <slot v-if="!isMobile" />
  </UiPopover>
</template>

<script setup lang="ts">
import { UiBaseModalClasses } from "./base/Modal.vue"

const emit = defineEmits(["update:modelValue", "close"])

type PopoverDrawerClasses = {
  popover: { root?: ClassValue; body?: ClassValue }
  drawer: Partial<UiBaseModalClasses>
}

const popoverDrawerModel = defineModel({ default: false })
const { isMobile } = useDisplay()
const props = defineProps<{
  id?: string
  classes?: Partial<PopoverDrawerClasses>
}>()

const close = (callback?: () => void) => {
  popoverDrawerModel.value = false

  callback?.()
}
</script>
